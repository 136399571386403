<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  padding: 0 0 10px;
}
.header .title {
  margin: 10px;
  font-size: 18px;
  font-weight: bold;
  text-indent: 2em;
  line-height: 30px;
  text-align: left;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.content {
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  padding: 10px;
  line-height: 30px;

  margin-bottom: 15px;
  display: flex;
  align-items: center;
  text-align: left;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.content input {
  width: 20px;
  height: 20px;
}
.content span {
  margin-left: 10px;
}

.content h3 {
  font-size: 18px;
  font-weight: 600;
  width: 85%;
  /* height: 60px; */
  text-indent: 1em;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
}
.content_btn {
  padding: 8px 15px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
.content_btn_del {
  background: #e05454;
}
.content_btn_edit {
  background: #7390ef;
}
.content_btn_view {
  background: green;
}
.content_btn_preview {
  background: #006180;
}
.list {
  padding: 0 10px;
  margin-top: 180px;
  min-height: auto;
}
</style>

<template>
  <div>
    <div class="header">
      <common-top title="审核" :isShowSearch="false"></common-top>
      <div class="title">
        {{ title }}
      </div>
      <div style="display: flex; justify-content: space-between">
        <div
          class="content_btn content_btn_del"
          style="margin-left: 10px"
          @click="showDialog('disagree')"
        >
          驳回
        </div>
        <div class="content_btn content_btn_view" @click="selectAll()">
          全选
        </div>
        <div
          class="content_btn content_btn_edit"
          style="margin-right: 10px"
          @click="showDialog('agree')"
        >
          通过
        </div>
      </div>
    </div>
    <div class="list">
      <div class="content" v-for="(data, index) in listCon" :key="index">
        <Checkbox-group v-model="social" @on-change="checkAllGroupChange()">
          <Checkbox :label="data.id">
            <span>{{ data.deptName_3rd + "--" + data.name }} : </span>
            <span>{{ data.comment }}</span>
          </Checkbox>
        </Checkbox-group>
      </div>
    </div>
    <loading-cmp v-if="showLoad"></loading-cmp>
    <load-more
      @refresh="onRefresh"
      :load-status="isLoadMore"
      :isShow="loadMoreShow"
    ></load-more>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>

    <div v-if="isConfirm">
      <confirm
        :title="`确认操作 `"
        @sure="oprationFun"
        @cancel="isConfirm = false"
      ></confirm>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
    <Modal v-model="isTime" fullscreen :closable="false">
      <div class="chooseDateFormItem">
        <div class="chooseDateFormItemTitle" style="margin-bottom: 5px">
          请选择发布日期：
        </div>
        <div class="detail">
          <DatePicker
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            :options="options3"
            :value="pickerValue"
            @on-change="setStartDateValue"
            placeholder="请选择时间"
            width="200px"
          ></DatePicker>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="chooseDate">确定</Button>
        <Button type="default" size="large" @click="cancelChoose">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable  */
import debug from "debug";
import Vue from "vue";
import moment from "moment";
import { wxConPost, getUserPm } from "../../api/httpApi";
import _ from "lodash";
import loadingCmp from "../../components/loadingCmp.vue";
import loadMore from "../../components/loadMoreData.vue";
import rnGaojianFooter from "../../components/rnGaojianFooter.vue";
import rnGaojian from "../../components/rnGaojian.vue";

import diaLogliu from "../../components/dialogLiu.vue";
import confirm from "../../components/commonConfirm.vue";

import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
let curDate = new Date();
const _d = debug("@pages:zdMessageReview");

moment.locale("zh-cn");

export default {
  name: "zdMessageReview",
  data () {
    return {
      isAll: false,
      title: '',
      social: [],
      showLoad: false,
      options3: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now();
        },
      },
      isUserPm: true,
      listCon: [],
      activeIndex: -1,
      pickerValue: "",
      isLoadMore: true,
      loadMoreShow: false,
      isConfirm: false,
      isTime: false,
      curCon: null,
      curIndex: -1,
      opration: "",

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },

  methods: {

    selectAll () {
      this.isAll = !this.isAll;
      if (this.isAll) {
        for (let i = 0; i < this.listCon.length; i++) {
          this.social.push(this.listCon[i].id);
        }

      } else {
        this.social = []
      }
    },
    back () {
      this.$router.replace("/tougaoEditor");
    },
    checkAllGroupChange () {
      console.log(this.social)
    },
    async onRefresh () {
      const limit = this.$store.state.limit ? this.$store.state.limit : 10;
      console.log(this.listCon.length < limit, this.listCon.length, limit);
      const query = {
        column: "",
        limit: 20,
        searchValue: this.title,
        skip: this.listCon.length,
        sort: { created: -1 },
        viewStatus: false,
      };
      try {
        this.showLoad = true;
        const ret = await wxConPost(
          "/services/gttt-content-mgt/dealComment/getComment",
          query
        );
        this.showLoad = false;
        const dataList = [];
        const data = ret.value;
        if (data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          let listData = [];
          for (const item of data) {
            listData = item;
            dataList.unshift(listData);
          }
          let arr1 = _.sortBy(dataList, "time");
          this.listCon = this.listCon.concat(arr1);

          this.loadMoreShow = true;
          this.isLoadMore = true;
        }
      } catch (error) {
        console.log("加载列表错误");
        console.log(error);
      }
    },
    async chooseDate () {
      console.log(this.pickerValue);
      try {
        const ret = await wxConPost(
          "/services/gttt-content-mgt/content/updateTime",
          {
            _cid: this.timeCid,
            time: this.pickerValue,
          }
        );
        this.diaLogFun("操作成功");
        this.getList();
        this.isTime = false;
      } catch (error) {
        this.diaLogFun("操作失败");
        this.isTime = false;
      }
    },
    setStartDateValue (date) {
      console.log(date);
      this.pickerValue = date;
    },
    async cancelChoose () {
      this.isTime = false;
    },
    // 获取栏目信息
    async getList () {
      const query = {
        column: "",
        limit: 20,
        searchValue: this.title,
        skip: 0,
        sort: { created: -1 },
        viewStatus: false,
      };
      try {
        this.showLoad = true;
        const ret = await wxConPost(
          "/services/gttt-content-mgt/dealComment/getComment",
          query
        );
        this.showLoad = false;
        const data = ret.value;
        const dataList = [];
        if (data && data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          for (const item of data) {
            let listData = item;
            dataList.unshift(listData);
          }

          this.listCon = _.reverse(_.sortBy(dataList, "updated"));
          console.log("数据");
          console.log(this.listCon);

          // 显示加载
          this.loadMoreShow = true;
          // 显示加载
          this.isLoadMore = true;
        }
      } catch (error) { }
    },
    diaLogFun (msg) {
      this.dailogshow = true;
      this.dailogoptions.content = msg;
      this.dailogoptions.textColor = "white";
      setTimeout(() => {
        this.dailogshow = false;
      }, 2000);
    },

    toEdit (cid) {
      this.$router.push({
        path: "/tougaoEditor",
        query: {
          cid,
        },
      });
    },
    toUrl (url) {
      this.$router.push({
        path: url,
      });
    },
    openDialog (data) {
      this.isTime = true;
      console.log(data.updated);
      this.pickerValue = moment(data.updated).format("YYYY-MM-DD HH:mm");
      console.log(this.pickerValue);
      this.timeCid = data._cid;
    },
    showDialog (opration) {
      if (this.social.length == 0) {
        alert('请选择评论')
        return
      } else {
        this.isok = '请确认操作'
      }

      this.opration = opration;
      this.isConfirm = true;
    },
    oprationFun () {
      console.log("-------", this.opration);
      if (this.opration == "agree") {
        for (let i = 0; i < this.social.length; i++) {
          this.agree(this.social[i]);
        }
        this.getList();
        this.isConfirm = false;
        return;
      }
      if (this.opration == "disagree") {
        for (let i = 0; i < this.social.length; i++) {
          this.disagree(this.social[i]);
        }
        this.getList();
        this.isConfirm = false;
      }
    },

    // 审核通过
    async agree (id) {

      try {

        // 设置提交审核状态
        await wxConPost("/services/gttt-content-mgt/dealComment/viewPass", {
          id: id,
          viewStatus: true,
        });
        this.diaLogFun("已将该内容审核为:通过");
        this.curCon = null;
        this.social = []
        this.getList();
        this.curIndex = -1;
      } catch (e) {
        this.diaLogFun("操作异常");
      }
    },
    // 驳回
    async disagree (id) {
      try {
        // 设置提交审核状态
        await wxConPost("/services/gttt-content-mgt/dealComment/deleteComment", {
          id: id,
        });
        this.diaLogFun("已将该内容审核为:驳回");
        this.curIndex = -1;
        this.social = []
      } catch (e) {
        this.diaLogFun("操作异常");
      }
    },
  },

  async created () {
    this.title = this.$route.query.title
    this.getList();
  },
  beforeCreate () {
    document.title = "站段留言审核发布";
  },

  components: {
    loadMore,
    rnGaojianFooter,
    rnGaojian,
    noPassCom,
    commonTop,
    diaLogliu,
    confirm,
    loadingCmp
  },
  filters: {
    dateFormat (time) {
      return moment(time).startOf("minutes").fromNow();
    },
  },
};
</script>
